<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong
              >Product Stock (
              <CIcon name="BarCode" :height="25" /> Barcode)</strong
            >
          </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm
            autocomplete="off"
            @submit.prevent="toggleConfirmationModal"
            v-else
          >
            <CCardBody>
              <CRow>
                <div class="col-md-12 mb-3">
                  <v-select
                    @open="onOpen"
                    @close="onClose"
                    @search="triggerSearch"
                    :options="products"
                    label="name"
                    v-model="selected_product"
                    placeholder="Search and select product"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }}
                      &#8594;
                      <CIcon name="ProductModel" />
                      <span class="badge badge-light"
                        >{{ option.product_model.name }}
                      </span>
                      &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }}
                      &#8594;
                      <CIcon name="ProductModel" />
                      <span class="badge badge-light"
                        >{{ option.product_model.name }}
                      </span>
                      &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!selected_product"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <template #list-footer>
                      <li
                        ref="load"
                        class="loader text-center"
                        v-show="page && isFetching && !selected_product"
                      >
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted"
                          >fetching product models...</span
                        >
                      </li>
                      <li class="loader text-center" v-show="isSearching">
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted">searching...</span>
                      </li>
                    </template>
                    <span slot="no-options">
                      <li
                        class="loader text-center"
                        v-show="!isSearching && !products.length && !isFetching"
                      >
                        <CIcon name="emojiSad" />
                        <span class="ml-2 text-muted">nothing found!</span>
                      </li>
                    </span>
                  </v-select>
                </div>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                  required
                    type="number"
                    autocomplete="off"
                    label="Stocks"
                    v-model="stocks"
                    min="1"
                    @change="check_stocks"
                    :addInputClasses="
                      stocks_invalid ? 'is-invalid' : null
                    "
                    :isValid="stocks ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Purchase Price"
                    v-model="purchase_price"
                    placeholder="Enter purchase price..."
                    required
                    min="0"
                    @change="check_purchase_price"
                    :addInputClasses="
                      purchase_price_invalid ? 'is-invalid' : null
                    "
                    :isValid="purchase_price ? true : null"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-right">
                <CButton
                  size="sm"
                  color="primary"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Submit</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
    <StockConfirmationModal
      :data="stock_data"
      @close="toggleConfirmationModal"
      :show="show_confirmation_modal"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
const StockConfirmationModal = () =>
  import("@/views/Stock/StockConfirmationModal");
export default {
  name: "StockAdd",
  components: {
    StockConfirmationModal,
  },
  data() {
    return {
      observer: null,
      searchKeyword: null,
      searchQuery: null,

      stock_data: [],

      purchase_price: null,
      stocks: null,
      stocks_invalid: false,
      purchase_price_invalid: false,

      show_confirmation_modal: false,
      selected_product: this.products,
    };
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("products/getProducts");
    this.searchQuery = _.debounce(
      () => this.fetch_search_items(this.searchKeyword),
      500
    );
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  watch: {
    searchKeyword: function(val) {
      this.searchQuery(val);
    },
    selected_product: function(val) {
      if (val === null) {
        this.$nextTick();
        this.observer.observe(this.$refs.load);
        this.fetch_search_items(" ");
      }
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.products.products,
      product_fetch_pagination: (state) =>
        state.stocks.product_fetch_pagination,
      isSaving: (state) => state.stocks.isSaving,
      isSearching: (state) => state.stocks.isSearching,
      isLoading: (state) => state.stocks.isLoading,
      isFetching: (state) => state.stocks.isFetching,
    }),
    page() {
      return this.product_fetch_pagination.current >=
        this.product_fetch_pagination.last
        ? false
        : this.product_fetch_pagination.current + 1;
    },
  },
  methods: {
    triggerSearch(keyword) {
      this.searchKeyword = keyword;
    },
    async onOpen() {
      if (!this.selected_product) {
        this.$store.commit("stocks/fetching", true);
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      } else {
        this.observer.disconnect();
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        if (this.page && !this.selected_product) {
          this.$store.dispatch("stocks/getProducts", this.page);
        }
      }
    },
    fetch_search_items(keyword) {
      if (typeof keyword === "string" || !keyword) {
        if (keyword.trim().length == 0) {
          if (this.selected_product === null) {
            this.$store.commit("stocks/fetching", true);
            this.$store.dispatch("stocks/getProducts");
          }
        } else {
          this.$store.commit("stocks/fetching", false);
          this.$store.dispatch("stocks/fetch_search_items", keyword);
        }
      }
    },
    check_purchase_price() {
      if (!this.purchase_price) {
        this.purchase_price_invalid = true;
      } else {
        this.purchase_price_invalid = false;
      }
    },
    check_stocks() {
      if (!this.stocks) {
        this.stocks_invalid = true;
      } else {
        this.stocks_invalid = false;
      }
    },
    toggleConfirmationModal() {
      this.stock_data = {
        product: this.selected_product,
        purchasing_codes: this.purchasing_codes_array,
        stocks: this.stocks,
        purchase_price: this.purchase_price,
      };
      this.show_confirmation_modal = !this.show_confirmation_modal;
    },
  },
};
</script>
